<template>
  <div class="thank-you main-container">
    <div class="container">
      <h1>{{ $t('thank_you_for_joining') }}</h1>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ThankYou',
};

</script>
